import axios from 'axios';

let baseURL = '';
export const bindAccount = (data) => {
  return axios.post(
    `${baseURL}/t-api/v1/invite/withdraw/account/bind`,
    data,
    {
      headers : {
        'Content-Type':'application/json;charset=UTF-8'
      }
    }
  )
}
export const bindBank = (data) => {
  return axios.post(
    `${baseURL}/t-api/v1/withdraw/account/bind`,
    data,
    {
      headers : {
        'Content-Type':'application/json;charset=UTF-8'
      }
    }
  )
}
export const startWithdraw = (data) => {
  return axios.post(
    `${baseURL}/t-api/v1/withdraw/withdraw`,
    data,
    {
      headers : {
        'Content-Type':'application/json;charset=UTF-8'
      }
    }
  )
}

export const withdrawDetail = (data) => {
  return axios.post(
    `${baseURL}/t-api/v1/withdraw/detail/list
    `,
    data,
    {
      headers : {
        'Content-Type':'application/json;charset=UTF-8'
      }
    }
  )
}

export const withdrawListBind = (data) => {
  return axios.post(
    `${baseURL}/t-api/v1/withdraw/list/bind
    `,
    data,
    {
      headers : {
        'Content-Type':'application/json;charset=UTF-8'
      }
    }
  )
}

