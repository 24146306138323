<template>
  <div class="circle">
    <div class="num">
      <slot name="num"></slot>
      <div v-if="lineShow" class="line"></div>
    </div>
    <slot name="text"></slot>
  </div>
</template>
<script>
export default {
  props: {
    lineShow: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped>
.circle {
  text-align: left;
  font-size: 15px;
  margin-left: 23px;
  margin-bottom: 12px;
}
.num {
  width: 22px;
  line-height: 22px;
  text-align: center;
  background: #FD3880;
  border-radius: 50%;
  position: relative;
  color: #fff;
  display: inline-block;
  margin-right: 8px;
}
.text {
  color: #8A00CF;
  display: inline-block;
  background: #F5F5F5;
  border-radius: 10px;
  padding: 8px 13px;
  vertical-align: middle;
  width: 66vw;
}
.line {
  height: 0px;
  width: 24px;
  border: 1px dashed #D4D4D4;
  position: absolute;
  left: -1px;
  top: 35px;
  transform: rotate(90deg);
}
</style>