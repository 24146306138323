import Vue from 'vue';
import App from './views/download/index.vue';
// import 'element-ui/lib/theme-chalk/index.css';
import { Button } from 'element-ui';
Vue.use(Button);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
