const mixin = {
  methods: {
    changeTime(ms) {
      const date = new Date(ms);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minu = date.getMinutes();
      let second = date.getSeconds();
      month = month > 9 ? month : `0${month}`;
      day = day > 9 ? day : `0${day}`;
      hour = hour > 9 ? hour : `0${hour}`;
      minu = minu > 9 ? minu : `0${minu}`;
      second = second > 9 ? second : `0${second}`;
      return `${year}.${month}.${day} ${hour}:${minu}:${second}`;
    },
    countWeekRang(value) {
      const time = value || Date.now();
      const now = new Date(time);
      const nowTime = now.getTime();
      let day = now.getDay();
      const oneDayTime = 24 * 60 * 60 * 1000;
      day = day === 0 ? 7 : day;
      //显示周一
      const MondayTime = nowTime - (day - 1) * oneDayTime;
      //显示周日
      const SundayTime = nowTime + (7 - day) * oneDayTime;
      //初始化日期时间
      const monday = new Date(MondayTime);
      const sunday = new Date(SundayTime);
      const year = now.getFullYear();
      let startMonth = monday.getMonth() + 1;
      let endMonth = sunday.getMonth() + 1;
      let startDay = monday.getDate();
      let endDay = sunday.getDate();
      startMonth = startMonth > 9 ? startMonth : `0${startMonth}`;
      endMonth = endMonth > 9 ? endMonth : `0${endMonth}`;
      startDay = startDay > 9 ? startDay : `0${startDay}`;
      endDay = endDay > 9 ? endDay : `0${endDay}`;
      this.date = `${startMonth}.${startDay}-${endMonth}.${endDay}`;
      this.startTime = `${year}-${startMonth}-${startDay}`;
      this.endTime = `${year}-${endMonth}-${endDay}`;
    }
  }
}
export default mixin;