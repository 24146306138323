<template>
  <section class="main">
    <img class="banner" :src="require('../../../assets/b-hw.png')" />
    <slot></slot>
  </section>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>
.main {
  background: #AD2CEE;
  min-height: 100%;
}
.banner {
  width: 100%;
}
</style>