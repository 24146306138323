// 首页界面接口
import axios from './request'
let baseURL = ''

// 首页接口
export const postUnion = (data) =>
  axios({
    url: `${baseURL}/t-api/v1/anchor/union/apply`,
    method: 'post',
    data
  })

// 获取手机验证码
export const getCode = (param) =>
  axios({
    url: `${baseURL}/t-api/v1/login/code?mobile=${param.mobile}&countryCode=${param.countryCode}`,
    method: 'post',
    contentTypejson: true
  })
export const login = () =>
  axios({
    url: `${baseURL}/t-api/v1/login/sms?mobile=13001915276&code=0000`,
    method: 'post',
    contentTypejson: true
  })
export const getAnchorDayData = (param) =>
  axios({
    url: `${baseURL}/t-api/v1/anchors/statistics/day?startTime=${param}`,
    method: 'get',
    contentTypejson: true
  })
export const getAnchorWeekData = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/anchors/statistics/week`,
    method: 'get',
    params: params,
    contentTypejson: true
  })
export const getDownloadUrl = (param) =>
  axios({
    url: `${baseURL}/t-api/v1/app/download/info?platform=${param}`,
    method: 'get',
    contentTypejson: true
  })
export const getMarqueeList = () =>
  axios({
    url: `${baseURL}/t-api/v1/invite/marquee?size=50`,
    method: 'get',
    contentTypejson: true
  })
export const getRankList = () =>
  axios({
    url: `${baseURL}/t-api/v1/invite/ranking/list?size=10`,
    method: 'get',
    contentTypejson: true
  })
export const getInviteList = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/invite/month/detail`,
    method: 'get',
    params: params,
    contentTypejson: true
  })
export const myInvite = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/invite/user/ranking`,
    method: 'get',
    params: params,
    contentTypejson: true
  })

export const applyPay = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/invite/withdraw/withdraw?totalFee=${params}`,
    method: 'get',
    contentTypejson: true
  })
export const myWithReward = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/invite/withdraw/my`,
    method: 'post',
    params: params,
    contentTypejson: true
  })
export const getPayList = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/invite/withdraw/detail`,
    method: 'post',
    params: params,
    contentTypejson: true
  })
export const getAccountInfo = () => 
  axios({
    url: `${baseURL}/t-api/v1/invite/withdraw/certification`,
    method: 'get',
    contentTypejson: true
  })
  
export const share = () =>
  axios({
    url: `${baseURL}/t-api/v1/invite/share`,
    method: 'get',
    contentTypejson: true
  })

//主播邀请列表
export const getAnchorInviteList = (word) =>
  axios({
    url: `${baseURL}/t-api/v1/union/getAnchorInviteList?keyword=${word}`,
    method: 'get',
    contentTypejson: true
  })
//工会信息
export const getUnionInfo = (id) =>
  axios({
    url: `${baseURL}/t-api/v1/union/info?unionId=${id}`,
    method: 'get',
    contentTypejson: true
  })

//用户信息
export const getUserInfo = (id) =>
  axios({
    url: `${baseURL}/t-api/v1/user/info`,
    method: 'get',
    contentTypejson: true
  })

//工会入驻申请
export const joinUnion = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/login/joinUnionRegist`,
    method: 'post',
    params: params,
    contentTypejson: true
  })

//工会下主播周数据 
export const getWeekStatistics = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/union/getAnchorWeekStatistics`,
    method: 'get',
    params: params,
    contentTypejson: true
  })
  //主播每周详细数据
export const getDayStatistics = (params) =>
  axios({
    url: `${baseURL}/t-api/v1/union/getAnchorDayStatistics`,
    method: 'get',
    params: params,
    contentTypejson: true
  })
export const getOrderDetails = (id) =>
  axios({
    url: `${baseURL}/t-api/v1/pay/order?tradeNo=${id}`,
    method: 'get',
    contentTypejson: true
  })


export const getCountryCodeList = () => 
  axios({
    url: `${baseURL}/t-api/v1/login/countryCodeList `,
    method: 'get'
  })