<template>
  <div id="app">
    <img class="d-bg" :src="require('../../assets/d-bg.png')" />
    <section class="main-content">
      <div class="logo"></div>
      <div @click="handleDownload" download class="download-btn">download now</div>
    </section>
    <div v-if="isWx" class="wx-open">
      <img :src="require('../../assets/open.png')" />
      <p>1、Click the three dots in the upper right corner</p>
      <p>2、Choose to open in browser to download</p>
    </div>
  </div>
</template>

<script>
import { getDownloadUrl } from '../../api/home';

export default {
  name: 'App',
  data() {
    return {
      apkUrl: '',
      isAndroid: true,
      isInitSuccess: false,
      from: ''
    }
  },
  methods: {
    downloadApp() {
      let u = navigator.userAgent;
      this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      let platform = this.isAndroid ? 0 : 1;
      getDownloadUrl(platform).then(data => {
        this.apkUrl = data.data;
      })
    },
    handleDownload() {
      if (this.from === 'anchor') {
        location.href = 'https://d1ubagn5hnng6h.cloudfront.net/apk/wanchor-release-2.0.5-202409280010.apk';
        return;
      }
      if(this.from != null && this.from != undefined){
        location.href = 'https://d1ubagn5hnng6h.cloudfront.net/apk/wanchor-release-2.0.5-202409280010.apk';
        return;
      }
      if (this.isInitSuccess) {
        ShareTrace.download();
      } else {
        location.href = 'https://d1ubagn5hnng6h.cloudfront.net/apk/wanchor-release-2.0.5-202409280010.apk';
      }
    }
  },
  computed: {
    isWx() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.match(/MicroMessenger/i) == "micromessenger";
    }
  },
  created() {
    // ShareTrace.init('d6a7cfc767b82f1a');
    const self = this;
    const search = location.search.substring(1);
    const searchParams = new URLSearchParams(search);
    this.from = searchParams.get('from');
    console.log(this.from);
    if (this.from === 'anchor') {
      return;
    }
    ShareTrace.init({
      appkey: 'd6a7cfc767b82f1a', //分配的appkey
      // param: 'CUSTOM PARAMS', //自定义参数，如果不设置默认会取url携带的参数
      success: function () {
        self.isInitSuccess = true;
        /*
          如果开启调起app功能建议在此处调用wakeUp()
        */
        ShareTrace.wakeUp();
        /*
          如果要打开页面就自动下载，建议在此处调用download()方法，
          不过此处调用可能会降低成功率，建议在点击方法中触发download()方法
        */
        //ShareTrace.download();
      },
      error: function(msg) {
        self.isInitSuccess = false;
      }
    });
  }
}
</script>

<style scoped>
html, body, #app {
  margin: 0;
  padding: 0;
  height: 100%;
}
.main-content {
  text-align: center;
  min-height: 100%;
  background: rgba(0, 0, 0, .5);
  position: relative;
  z-index: 1;
  padding-top: 30vw;
  padding-bottom: 10vw;
  box-sizing: border-box;
}
.d-bg {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  animation: move 10s linear infinite alternate;
}
@keyframes move {
  0% {
    left: 0;
  }
  100% {
    left: -100%
  }
}
.logo {
  background: url("../../assets/logo3.png");
  width: 160px;
  height: 142px;
  background-size: 100% 100%;
  margin: 0 auto;
}
.wz1 {
  background: url("../../assets/wz1.png");
  width: 192px;
  height: 58px;
  background-size: 192px 58px;
  margin: 20px auto 0 auto;
}
.wz2 {
  background: url("../../assets/wz2.png");
  width: 192px;
  height: 58px;
  background-size: 192px 58px;
  margin: 20px auto 0 auto;
}
.wz3 {
  background: url("../../assets/wz3.png");
  width: 288px;
  height: 58px;
  background-size: 288px 58px;
  margin: 20vw auto 0 auto;
}
.download-btn {
  width: 264px;
  height: 50px;
  background: linear-gradient(90deg, #FE3EA0 0%, #FF9B6F 100%);
  border-radius: 25px;
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 50px;
  margin: 12vw auto 0 auto;
  text-decoration: none;
}
.wx-open {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .8);
  top: 0;
  left: 0;
  z-index: 10;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  padding-top: 30vw;
  padding-left: 20vw;
}
.wx-open img {
  position: absolute;
  right: 0;
  top: 0;
}
.wx-open > p {
  margin: 0;
}
.wx-open > p:nth-child(2) {
  margin-bottom: 20px;
}
</style>
